import { ReactElement, Suspense, lazy, useEffect } from 'react'
import { useAccountSummaryV2Query } from 'syf-api'
import { mfeConfig } from 'const/configMFE'
import { useMFEConfigs } from 'hooks'
import useAccountInfo from 'hooks/useAccountInfo'
import type { MFEDefaultAnalytics } from 'typings/Analytics'
import useOrchestratorHelpers from 'orchestrator/OrchestratorHelpers'
import { refreshChunk } from 'helpers/utils/utils'
import type AccountCardAdapterProps from './AccountCardAdapter.types'
// defer load & code split this to a separate chunk for initial load perf
const AccountCardMFE = lazy(() =>
  refreshChunk(
    () =>
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "mfe-acct-card" */
        'account-card-mfe'
      ),
    'account-card-mfe'
  )
)

const AccountCardAdapter = ({
  accountId,
  isExpandedByDefault
}: AccountCardAdapterProps): ReactElement => {
  const { getAccountCardConfig } = useMFEConfigs()
  const { setAccountCardLoading } = useOrchestratorHelpers()
  const currentAccountData = useAccountInfo(accountId)
  const analytics: MFEDefaultAnalytics = {
    ClientName: currentAccountData?.product.clientId || 'generic',
    Product: currentAccountData?.product.type || 'generic'
  }

  const { isLoading } = useAccountSummaryV2Query({
    config: mfeConfig,
    keyProps: { accountId, accountIdType: 'guid' },
    /**
     * The query is ran inside AccountCardMFE, so there is no need
     * to enable it here. We only want to know if it is loading.
     */
    queryOptions: { enabled: false }
  })

  useEffect(() => {
    setAccountCardLoading({ accountId, isLoading })
  }, [isLoading])

  return (
    <Suspense fallback={<></>}>
      <AccountCardMFE
        {...getAccountCardConfig({
          accountId,
          analytics
        })}
        key={accountId}
        isExpandedByDefault={isExpandedByDefault}
      />
    </Suspense>
  )
}

export default AccountCardAdapter
