export const MOCK_TRIGGER_TOOL_TIPS = {
  INVALID_TOKEN:
    'Uses ID token issued from /authorize to retrieve a session ID from /introspect. In order to see error page, the page needs to be refreshed on login screen.',
  EXPIRE_TOKEN:
    'Uses ID token issued from /authorize to retrieve a session ID from /introspect. Won’t generate an error screen but will refresh the page to restart the auth flow to get a valid ID token. Need to fresh the login screen for this to happen.',
  DELAY_INTROSPECT:
    'Delays the mocked introspect call for a certain amount of time. Won’t generate an error screen. Useful to check how the UI behaves while waiting for the introspect call to complete.',
  AUTH_POST_ERROR:
    'After authenticating user information a second /authorize call is made to obtain an access token. If there is an issue, a url will be returned from the auth server that has error and error_description query parameters in it signifying an error and in return render a specific error page.',
  ACC_NOT_FOUND:
    'Request is made to /authenticate. Will return a 422 response if credentials entered doesn’t match and will throw an error screen upon signing in.',
  SESSION_NOT_FOUND:
    'Makes a request to pii-authenticate and returns a status code of 401. Will render cat error page.',
  INVALID_SESSION:
    'Throws a 401 from pii-authenticate. Renders cat error page.',
  CLIENT_ERROR:
    'Error from /complete request and can’t return a authenticated ID token.',
  AUTOPAY_ENROLLED: 'Force /autopay response to have enrolled: true',
  NO_ACTIVE_ACCOUNTS:
    'Goes through the whole auth flow and if the user does not have any active accounts, it will render a no active accounts screen',
  CARDS_LIMIT:
    'Limit the number of cards shown when mock data is enabled. To show no cards refer to No Active Accounts trigger',
  SECURED_ACC_BLOCKED:
    'For secured accounts, request is made to /authenticate, returning a 422 response if account has a blocked code, throwing an error screen upon signing in.',
  PROXY_QUICK_AUTHENTICATE:
    'Proxy calls to /quick-authenticate to the api design server for a live server mock',
  ACTIVATION_ACCOUNT_UNSUPPORTED:
    'For activation signin requests to /quick-authenticate, returns a 422 error with error code for Account Unsupported error.',
  ACTIVATION_IOVATION:
    'For activation signin requests to /quick-authenticate, returns a 422 error with error code for Iovation (Bad Device) error.',
  ACTIVATION_MAX_ATTEMPTS:
    'For activation signin requests to /quick-authenticate, returns a 422 error with error code for Max Attempts Reached error.',
  ACTIVATION_FETCH_ACCOUNT:
    'For activation signin requests to /quick-authenticate, returns a 500 error with error code for Fetch account metadata error.',
  ACTIVATION_EXTERNAL_STATUS:
    'For requests to /cards/activate, returns a 422 error with error code for External status error',
  ACTIVATION_ALREADY_ACTIVATED:
    'For requests to /cards/activate, returns a 422 error with error code for Card already activated error',
  ACTIVATION_AUTH_SHELL:
    'For requests to /cards/activate, returns a 422 error with error code for Auth shell error',
  ACTIVATION_INVALID_EXTERNAL_CODE:
    'For requests to /cards/activate, returns a 422 error with error code for Invalid External code error',
  ACTIVATION_CUSTOM_ERROR_CODE:
    'For requests to /cards/activate, returns a 422 error with a custom error code'
}
