/**
 * This utility function creates a custom promise that can be resolved using
 * the returned resolver function for resolution though external contexts
 * @returns array - [ promise, resolver ]
 * @returns promise - the custom promise created
 * @returns function - resolver - exposed function to fulfill the promise externally.
 */
const createPromise = () => {
  let resolver
  return [
    new Promise((resolve) => {
      resolver = resolve
    }),
    resolver
  ]
}

export default createPromise
