import { GlobalAppStyles } from 'globalStyles'
import { ReactElement, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClientProvider as QueryClientProviderLegacy } from 'react-query'
import { FlagsProvider } from 'flagged'
import AppRoutes from 'AppRoutes'
import queryClient, { queryClientLegacy } from 'const/queryClient'
import SettingsProvider from 'context/settingsContext'
import UserProvider from 'context/userContext'
import { IOSBodyScrollBlockerProvider } from 'context/iOSBodyScrollBlockerContext'
import Orchestrator from 'orchestrator/Orchestrator'
import Footer from 'ui/molecules/Footer'
import { featureFlagsEventName } from 'const/flags/globals'
import { setNewRelicSessionUserId } from 'helpers/newRelic'
import { getFeatureFlagsInStore } from 'helpers/featureFlags'
import theme from 'theme'
import { AppWrapper } from 'ui/App/AppWrapper.styles'
import handleIVALoad from 'helpers/handleIVALoad'
import handleQualtricsLoad from 'helpers/handleQualtricsLoad'
import ErrorRoutingWrapper from './ErrorRoutingWrapper'

/** Wraps components in all the provider/context components */
const Providers = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState(getFeatureFlagsInStore())
  useEffect(() => {
    const handleFlagsStoreChange = () =>
      setFeatureFlags(getFeatureFlagsInStore())
    // monitor for feature flag updates to push the changes to the provider
    window.addEventListener(featureFlagsEventName, handleFlagsStoreChange)
    // cleanup the event listener on unmount
    return () =>
      window.removeEventListener(featureFlagsEventName, handleFlagsStoreChange)
  }, [])
  return (
    <QueryClientProvider client={queryClient}>
      <QueryClientProviderLegacy client={queryClientLegacy}>
        <Orchestrator>
          <ThemeProvider theme={theme}>
            <UserProvider>
              <SettingsProvider>
                <IOSBodyScrollBlockerProvider>
                  <FlagsProvider features={featureFlags}>
                    {children}
                  </FlagsProvider>
                </IOSBodyScrollBlockerProvider>
              </SettingsProvider>
            </UserProvider>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </Orchestrator>
      </QueryClientProviderLegacy>
    </QueryClientProvider>
  )
}

const App = (): ReactElement => {
  const [iconsImportComplete, setIconsImportComplete] = useState(false)

  useEffect(() => {
    /**
     * Provides icons for dependencies that need to access them.
     * These icons are dynamically imported (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import)
     * in order to split the code to a different bundle and reduce loading times (https://webpack.js.org/guides/code-splitting/).
     * Webpack magic comments are used (https://webpack.js.org/api/module-methods/#magic-comments)
     * to specify a different chunk name for them.
     *
     * Setting the iconsImportComplete state is to trigger a rerender once the icons are fully imported to ensure libraries and MFE's
     * have access to needed icons.
     */
    import(
      /* webpackChunkName: "fortawesome" */ 'const/legacyIconLibrary'
    ).then((module) => {
      module.default()
      setIconsImportComplete(!iconsImportComplete)
    })
    // sets new relic user id in case session id exists already from /introspect
    setNewRelicSessionUserId()
    handleIVALoad()
    handleQualtricsLoad()
    // preload critical modules
    import('ui/pages/GetAccessToken')
  }, [])

  return (
    <Providers>
      <AppWrapper>
        <GlobalAppStyles />
        <ErrorRoutingWrapper>
          <AppRoutes />
        </ErrorRoutingWrapper>
        <Footer />
      </AppWrapper>
    </Providers>
  )
}

export default App
