import useAccountInfo from 'hooks/useAccountInfo'
import { cardHeaderLabel } from 'account-card-mfe/lib/helpers/cardHeaderLabel'
import getAccountType from 'account-card-mfe/lib/helpers/getAccountType'
import { i18n } from 'strings/i18n'
import { AccountHeader, AccountSubtitle } from './ActivityAccountHeader.styles'

interface ActivityAccountHeader {
  accountId: string
}

const ActivityAccountHeader = ({ accountId }: ActivityAccountHeader) => {
  const currentAccountData = useAccountInfo(accountId)
  const accountLast4 = currentAccountData?.accountInfo.accountLast4
  const productType = currentAccountData?.product.type
  const accountType = getAccountType(productType)

  const labels = cardHeaderLabel(accountType)

  return (
    <AccountHeader
      title={currentAccountData?.product?.displayName}
      subtitle={
        <AccountSubtitle>{`${i18n({
          labels
        })} ${accountLast4}`}</AccountSubtitle>
      }
      img={currentAccountData?.card?.cardArtURL}
      alt={`${currentAccountData?.product?.displayName} Logo`}
    />
  )
}

export default ActivityAccountHeader
