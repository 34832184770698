import getSessionAuthFlow from 'helpers/authFlows/getSessionAuthFlow'
import { PAYLATER_AUTH_FLOW } from 'const/constants'

/**
 * Ensures that the isNotificationsEnabled flag returns false for non-paylater flows
 * @param isFlagEnabled boolean
 * @returns boolean
 */
const handleNotificationsEnabledFlag = (isFlagEnabled = false): boolean => {
  if (!isFlagEnabled || getSessionAuthFlow() !== PAYLATER_AUTH_FLOW) {
    return false
  }

  return isFlagEnabled
}

export default handleNotificationsEnabledFlag
